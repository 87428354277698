<template>
<v-app >
  <v-main >
  <NFTMint />
  </v-main>
  </v-app>
</template>

<script>
import NFTMint from './components/NFTMint.vue'

export default {
  name: 'App',
  components: {
    NFTMint
  }
}
</script>

<style>
#app {
}
</style>
