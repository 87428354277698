<template>
  <v-layout fluid class="character-page">
    <v-container fluid>
      <v-row class="social">
        <v-col cols=4 class="pa-1">
          <a href="https://twitter.com/tokenists" target="_blank">
            <v-icon small>mdi-twitter</v-icon><span class="d-none d-sm-inline"> Twitter</span>
          </a>
        </v-col>
        <v-col cols=4 class="pa-1">
          <a href="https://www.instagram.com/thetokenists/" target="_blank">
            <v-icon small>mdi-instagram</v-icon><span class="d-none d-sm-inline"> Instagram</span>
          </a>
        </v-col>
        <v-col cols=4 class="pa-1">
          <a href="https://discord.gg/yJXxhtfXRA" target="_blank">
            <v-icon small>mdi-discord</v-icon><span class="d-none d-sm-inline"> Discord</span>
          </a>
        </v-col>
      </v-row>
     
      <v-row class="hero">
        <v-col cols=12 class="pa-0 text-center">
          <v-img  width="80%" height="800" class="mx-auto"
            src="https://storage.googleapis.com/prove-anything.appspot.com/sites/GalleryLand/proofGroups/zQZiZDMlVRYLD36xqHrG/documents/2022/5/Tokenists Gallery (1)-1654675142342.jpg">
          </v-img>
        </v-col>
      </v-row>
      <v-row class="welcome">
        <v-col cols=12 class=" text-center">
          <h1>Welcome to the Tokenist Art Collective</h1>
          <h2>10,000 NFT Artists curating the future of virutal art galleries</h2>
          <h2>Be one of the driving forces behind tGallyland, join us today.</h2>
        </v-col>
      </v-row>
      <v-row  class="text px-4 my-8">
          <v-col cols=10 class=" text-center box mx-auto py-3 px-4 mintBox">
<h2>          To join us mint your own tokenist to claim and access your own 3D gallery and to be part of the foundation DAO that will decide how the future of the project progresses
</h2>
<p>The first 7500 Tokenists are on ETH, priced at 0.2 ETH each. Thisis a MultiChain platform and contract and you will be to mint and transfer to other chains</p>

        <span v-if="nftContext.loading" class="start" @click="nft.popup = true">Loading please wait</span>
        <span v-if="!nftContext.loading && !nftContext.hasWeb3" class="start" style="color:#FF0000"
          @click="nft.popup = true">No metamask found</span>
        <button v-if="nftContext.shouldShowConnect()" class="mintAction" @click="nft.connectWallet()">Connect</button>

        <div class="text-center py-3" v-if="nftContext.shouldShowMint()" >      

                {{ nftContext.total - nftContext.totalMinted }} / {{ nftContext.total }} remaining
              </div>
              <div class="text-center py-3">
                <v-btn x-large @click="nft.incMintCount()" color="#4F2275" >
                <v-icon large>mdi-arrow-up-circle</v-icon>
                </v-btn>
                <span class="mintCount">{{ nft.mintCount }}</span>
                <v-btn  x-large  @click="nft.decMintCount()" color="#4F2275">
                <v-icon large>mdi-arrow-down-circle</v-icon>
                </v-btn>
              </div>
              <div  class="text-center py-3" v-if="!nft.useAlt">
                  {{(nftContext.ethPrice * nft.mintCount).toFixed(2)}} {{nftContext.ccy}}
              </div> 
              <div class="text-center py-3">
                (max mint 10 per wallet)
              </div>
              <div class="text-center py-3" v-if="!nft.useAlt">
                <v-btn x-large class="mx-auto" @click="nft.mint()" color="#4F2275" >
                  Mint Now
                </v-btn>
              </div>
              </v-col>
          </v-row>

      <v-row class="carousel">
        <v-col cols=12 md=7 class="carousel__box">
          <div>
            <h4>Meet the Artists</h4>
            <p>
              Emulating artists from the great masters to modern icons, each Tokenist is a unique character
              with custom traits and features, allowing you to choose your favorite artist combination.
            </p>
            <p>
              Exhibit your art in a Metaverse gallery fronted by your own unique Tokenist.
            </p>
          </div>
        </v-col>
        <v-col cols=12 md=5 class="carousel__images">
          <v-carousel cycle height="400" hide-delimiter-background show-arrows-on-hover>
            <v-carousel-item
              src="https://firebasestorage.googleapis.com/v0/b/prove-anything.appspot.com/o/microsites%2Fthetac.club%2Fthumbnails%2FNjideka%20Akunyili%20Crosby_512x512.jpg?alt=media&token=46b92d5a-fdef-4b1e-9c23-a5312de30877"
              reverse-transition="fade-transition" transition="fade-transition"></v-carousel-item>
            <v-carousel-item
              src="https://firebasestorage.googleapis.com/v0/b/prove-anything.appspot.com/o/microsites%2Fthetac.club%2Fthumbnails%2FFrida%20Kahlo_512x512.jpg?alt=media&token=3b917235-9dd8-447d-95f3-3a40367d31e0"
              reverse-transition="fade-transition" transition="fade-transition"></v-carousel-item>
            <v-carousel-item
              src="https://firebasestorage.googleapis.com/v0/b/prove-anything.appspot.com/o/microsites%2Fthetac.club%2Fthumbnails%2FGeroga%20O'Keefe_512x512.jpg?alt=media&token=99a7b303-97da-4651-bfdf-382aeb8de3e3"
              reverse-transition="fade-transition" transition="fade-transition"></v-carousel-item>
            <v-carousel-item
              src="https://firebasestorage.googleapis.com/v0/b/prove-anything.appspot.com/o/microsites%2Fthetac.club%2Fgallary3.jpg?alt=media&token=bf9330fe-7e6c-48fd-a4f5-347aa1f22158"
              reverse-transition="fade-transition" transition="fade-transition"></v-carousel-item>
            <v-carousel-item
              src="https://firebasestorage.googleapis.com/v0/b/prove-anything.appspot.com/o/microsites%2Fthetac.club%2Fthumbnails%2FGraffiti%20Artist_512x512.jpg?alt=media&token=f50cade5-8801-42e6-86c0-6bf3ba471702"
              reverse-transition="fade-transition" transition="fade-transition"></v-carousel-item>
          </v-carousel>


        </v-col>
      </v-row>
      <v-row class="text px-4 my-8">
        <v-col cols=10 class=" text-center no-box mx-auto">
          <h2>An artist and their gallery</h2>
          <p>
            The Tokenist is the curator of your gallery space where you can display digital artwork or create an
            entirely new Metaverse art show.
          </p>
          <p>
            As one of the foundational members of GalleryLand you will we able to create propositions about how to improve and expand the galleries. As GalleryLand expands - the value of your say in it will also expand

          </p>
          <p>
              The goal of Galleryland is to exand the use and adoption of virtual art galleries from niche NFT projects, to schools,, insitutions and to real world galleries
          </p>
        </v-col>
      </v-row>
      <v-row class="text px-4 my-8">
        <v-col cols=2 class=" text-center no-box mx-auto">
          <v-img max-width=200
            src="https://firebasestorage.googleapis.com/v0/b/prove-anything.appspot.com/o/microsites%2Fthetac.club%2Fthumbnails%2FGraffiti%20Artist%203_200x200.jpg?alt=media&token=552998d2-ed51-48a6-b125-912e88d8e178">
          </v-img>
        </v-col>
        <v-col cols=2 class=" text-center no-box mx-auto">
          <v-img max-width=200
            src="https://firebasestorage.googleapis.com/v0/b/prove-anything.appspot.com/o/microsites%2Fthetac.club%2Fthumbnails%2FJuan%20Antonio%20Guirado_200x200.jpg?alt=media&token=ed427e69-bd27-41fb-82c3-90ef235ba920">
          </v-img>
        </v-col>
        <v-col cols=2 class=" text-center no-box mx-auto">
          <v-img max-width=200
            src="https://firebasestorage.googleapis.com/v0/b/prove-anything.appspot.com/o/microsites%2Fthetac.club%2Fthumbnails%2FMark%20Bradford_200x200.jpg?alt=media&token=922bbe37-38c5-4a5c-afe1-4bf80b6c6740">
          </v-img>
        </v-col>
        <v-col cols=2 class=" text-center no-box mx-auto">
          <v-img max-width=200
            src="https://firebasestorage.googleapis.com/v0/b/prove-anything.appspot.com/o/microsites%2Fthetac.club%2Fthumbnails%2FTakashi%20Murakami_200x200.jpg?alt=media&token=9e38bfbe-5313-490e-8f6f-0f4d0f8c3fa4">
          </v-img>
        </v-col>
        <v-col cols=2 class=" text-center no-box mx-auto">
          <v-img max-width=200
            src="https://firebasestorage.googleapis.com/v0/b/prove-anything.appspot.com/o/microsites%2Fthetac.club%2Fthumbnails%2FSalvador%20Dali_200x200.jpg?alt=media&token=14c20712-911e-49a0-9965-2dec55e11e6b">
          </v-img>
        </v-col>
        <v-col cols=2 class=" text-center no-box mx-auto">
          <v-img max-width=200
            src="https://firebasestorage.googleapis.com/v0/b/prove-anything.appspot.com/o/microsites%2Fthetac.club%2Fthumbnails%2FYoyoi%20Kasuma_200x200.jpg?alt=media&token=66ec84fc-32c5-4b04-ab41-53be1a568757">
          </v-img>
        </v-col>
      </v-row>
      <v-row class="text px-4 my-8">
        <v-col cols=10 class=" text-center box mx-auto">
          <h2>Project Highlights</h2>
          <p>⭐ The Tokenists is a PFP project, featuring 10,000 unique artists, with hand drawn features taken from
            classic and modern artists with full rarity and traits.</p>

          <p>⭐ The Tokenists is also a land project. By owning a tokenist you'll get full ownership of one of our 3d
            virtual galleries which will be combined together in the first every metaverse museum - think the Louvre in
            Cyberspace - with you getting your own section of it.</p>

          <p>⭐ The Tokenists is a DAO. By owning a tokenists you'll get to vote on and contraol the future of GalleryLand and be part of how we expand our world</p>

          <p>⭐ The Tokenists is also your own shop window! Unlike other gallery projects that only let you show off a
            few NFT's you own - and generally only on Ethereum - The Tokenists lets you show off links to not just NFT's
            you might own, but if you are a creator, it can show art you want to sell - which could be NFT's or could be
            links to purchase real physical items. The Tokenists is not only the first artist-owned metaverse gallery -
            it's also the first metaverse art shopping gallery.</p>

        </v-col>
      </v-row>
      <v-row >
        <v-col cols=12 class="pa-8">
          <v-img width="70%" class="mx-auto"
            src="https://storage.googleapis.com/prove-anything.appspot.com/sites/GalleryLand/proofGroups/zQZiZDMlVRYLD36xqHrG/documents/2022/5/Tokenist Logo Synthwaved-1654675140462.png">
          </v-img>
        </v-col>
      </v-row>
      <v-row class="text px-4 my-8">
        <v-col cols=12 md=10 class=" text-center no-box mx-auto">
          <v-img
            src="https://firebasestorage.googleapis.com/v0/b/prove-anything.appspot.com/o/microsites%2Fthetac.club%2FGallery.jpg?alt=media&token=510e7c54-f65b-4109-98c4-76cc1c63ae77">
          </v-img>
        </v-col>
      </v-row>
      
      <v-row class="text px-4 my-8">
        <v-col cols=10 class=" text-center box mx-auto">
          <h2>10,000 Tokenists - 0.2 ETH each</h2>
          <p>
            As the owner of a Tokenists you'll always get the most expansive and extenable gallery. 
          </p>
          <p>
            The revenue from the mint will be directly used to build the Metaverse Museum housing the galleries, and to enhance and improve the gallery interfaces
          </p>
        </v-col>
      </v-row>
      <v-row class="text px-4 my-8">
        <v-col cols=12 md=10 class=" text-center no-box mx-auto">
          <h2>Project Timeline</h2>
          <v-timeline color="#6DFDE4" :dense="$vuetify.breakpoint.smAndDown">
            <v-timeline-item color="#6DFDE4">
              <span slot="opposite">Phase 1</span>
              <v-card class="elevation-2" color="#4F2476">
                <v-card-title class="text-h5">
                  <div class="mx-auto">Preview</div>
                </v-card-title>
                <v-card-text>
                  <p>
                    In our preview we are pre-allocating Tokenists to charities and good causes.
                  </p>
                  <p>
                    To help charities and selected artists raise money we are giving away
                    a number of Tokenists for them to fill with their own
                    art to auction for their specific cause.
                  </p>
                  <p>
                    We will also be taking requests and ideas for features for our artists.
                    Join our Discord and get involved!
                  </p>
                </v-card-text>
              </v-card>
            </v-timeline-item>
            <v-timeline-item color="#56FBEB">
              <span slot="opposite">Phase 2</span>
              <v-card class="elevation-2"  color="#4F2476">
                <v-card-title class="text-h5">
                  <div class="mx-auto">Mint</div>
                </v-card-title>
                <v-card-text>
                  <p>
                    In the initial public mint people may buy up to 10 Tokenists,
                    priced at 0.2 ETH each, with proceeds used to support the next phases of the project.
                  </p>
                </v-card-text>
              </v-card>
            </v-timeline-item>
            <v-timeline-item color="#38FEF1">
              <span slot="opposite">Phase 3</span>
              <v-card class="elevation-2" color="#4F2476">
                <v-card-title class="text-h5">
                  <div class="mx-auto">Tokenist Reveal</div>
                </v-card-title>
                <v-card-text>
                  <p>
                    Next we will reveal all of the features and properties across the Collective.
                    Some Tokenist features will be more rare or unique than others, but it is more important to us
                    that you choose a character that represents your gallery best.
                  </p>
                </v-card-text>
              </v-card>
            </v-timeline-item>


            <v-timeline-item color="#4CFDED">
              <span slot="opposite">Phase 4</span>
              <v-card class="elevation-2" color="#4F2476">
                <v-card-title class="text-h5">
                  <div class="mx-auto">Customizable Galleries</div>
                </v-card-title>
                <v-card-text>
                  <p>
                    Immediately at launch we will be opening up VR customizable galleries. Each owner of a tokenist will
                    be able to upload art and nft's and customize rooms, walls, floor, ceilings and the size and style
                    of every piece of art into their own private gallery
                  </p>
                </v-card-text>
              </v-card>
            </v-timeline-item>

            <v-timeline-item color="#ADC1FC">
              <span slot="opposite">Phase 5</span>
              <v-card class="elevation-2" color="#4F2476">
                <v-card-title class="text-h5">
                  <div class="mx-auto">Metaverse Galleries</div>
                </v-card-title>
                <v-card-text>
                  <p>
                    After the 3D galleries have launched, we will then introduce the Metaverse Museum.
                  </p>
                  <p>
                    We will be building and connecting galleries in a meseum metaverse.
                    Unlike other open-world metaverses, ownership of spaces will be only
                    allocated to members of the Tokenist Art Collective.
                  </p>
                  <p>
                    The world will be built as a giant art museum, granting anyone entrance
                    to wander and browse. Only Tokenists, however, can own and control the space.
                  </p>
                </v-card-text>
              </v-card>
            </v-timeline-item>


            <v-timeline-item color="#E0B2FB">
              <span slot="opposite">Phase 6</span>
              <v-card class="elevation-2" color="#4F2476">
                <v-card-title class="text-h5">
                  <div class="mx-auto">New Editions</div>
                </v-card-title>
                <v-card-text>
                  <p>
                    Any Future editions of Tokenists will be
                    dropped to existing Collective members first.
                  </p>
                  <p>
                    These may include special editions designed around a specific artistic movement.
                  </p>
                  <p>
                    Collective members can add the new art to their gallery or trade it on OpenSeas.
                  </p>
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-col>
      </v-row>
      <v-row class="welcome mb-5">

        <v-col cols=12 class="py-2">
          <div class="text-center py-3 start" style="font-size:24px">
            {{ nftContext.total - nftContext.totalMinted }} / {{ nftContext.total }}
            <!--    <button  class="mintAction" style="font-size:21px">MINTING SOON</button> -->
            <button v-if="nftContext.shouldShowMint()" class="mintAction" @click="nft.popup = true">MINT NOW</button>
            <button v-if="nftContext.shouldShowConnect()" class="mintAction"
              @click="nft.connectWallet()">Connect</button>
            <!--{{nftContext.ethPrice}} {{nftContext.ccy}} -->
          </div>
        </v-col>
        <v-col cols=12 md=10 class=" text-center mx-auto">
          <h1>Project Team</h1>
          <div class="no-box">
            It might be common in an NFT project to remain anonymous,
            but we want to be open! As trusted partners to
            charities and good causes, it's important to us that this project not only succeeds
            in the long term, but that it also has visible accountability behind it.<br />
            Many of the team behind TAC are also the team behind <a
              href="https://proveanything.com">ProveAnything.com</a>
          </div>
        </v-col>
        <v-col cols=6 sm=4 class=" text-center mx-auto">
          <div class="box mx-2 pa-1">
            <h2>Glenn Shoosmith</h2>
            <p>
              Glenn is a technical founder and builder of SAAS software.
              He created The Tokenist Art Collective to help artists carve out their own digital space and to
              give them representation in the expanding blockchain world.
              Glenn is passionate about creating new ways for artists and good causes to generate revenue.
            </p>
          </div>
        </v-col>
        <v-col cols=6 sm=4 class=" text-center mx-auto">
          <div class="box mx-2 pa-1">
            <h2>Charlie Cox</h2>
            <p>
              A passionate advocate for decentralised and distributed technology, Charlie first got involved with
              blockchain and crypto in 2011
              and is just pleased the rest of the world has caught up!
              He will also be using his 20+ years experience in technology and marketing to help grow The Collective and
              shape their world,
              hopefully without disappearing into the Metaverse himself.
            </p>
          </div>
        </v-col>
        <v-col cols=6 sm=4 class=" text-center mx-auto">
          <div class="box mx-2 pa-1">
            <h2>Claire Pettifor</h2>
            <p>
              Claire is an illustrator and digital designer specialising in UI and UX design.
              She designs effective user experiences, solves problems and communicates complex ideas
              and messages through illustration and design.
            </p>
          </div>
        </v-col>
        <v-col cols=6 sm=4 class=" text-center mx-auto">
          <div class="box mx-2 pa-1">
            <h2>Steve Sampson</h2>
            <p>
              Steve has spent much of his life in the leisure and conservation world. He co-founded the National Schools
              Environmental Art Competition in 1998.
              Steve is helping reach out to charities, good causes and people passionate about creativity.
            </p>
          </div>
        </v-col>
        <v-col cols=6 sm=4 class=" text-center mx-auto">
          <div class="box mx-2 pa-1">
            <h2>Martin Alden</h2>
            <p>
              Martin Alden has an extensive knowledge of the retail sector and affiliate platforms.
              He also works closely with organisations who support charities and not for profits.
            </p>
          </div>
        </v-col>
        <v-col cols=6 sm=4 class=" text-center mx-auto">
          <div class="box mx-2 pa-1">
            <h2>Catalina Guirado</h2>
            <p>
              Catalina is the daughter of late, internationally acclaimed Spanish artist Juan Antonio Guirado. Catalina
              is based in LA and is an accomplished artist, designer, art event planner, curator and marketer of
              artists. Catalina also is the Director of the Guirado Legacy.
            </p>
          </div>
        </v-col>

      </v-row>

    </v-container>
  </v-layout>
</template>

<script>

import PA from '@proveanything/base'
import '@proveanything/ethereum'

console.log("---", PA)
// config the PA link
PA.setup({ host: "https://beta.proveanything.com" })


export default {
  name: 'NFTMint',
  props: {
  },
  data() {
    return {
      nft: { popup: false, mintCount: 1, useAlt: false, noticies: [] },
      nftContext: {
        shouldShowMint() { return false },
        shouldShowConnect() { return false },
        shouldShowPublicMint() { return false },
        shouldShowAltPublicMint() { return false },
        shouldShowAccesslistMint() { return false },
      },
      onAccessList: null,
    }
  },
  async mounted() {

    this.nft.mint = async (number = null) => {
      number = number || this.nft.mintCount || 1
      console.log("Do mint")
      const result = await this.nftContext.mint(number)
      console.log(result)
    }
    this.nft.altMint = async (number = null) => {
      number = number || this.nft.mintCount || 1
      console.log("Do alt mint")
      const result = await this.nftContext.altMint(number)
      console.log(result)
    },
      this.nft.connectWallet = async () => {
        // specific action to connect the wallet
        const result = await this.nftContext.connectWallet()
        console.log(result)
      },
      this.nft.incMintCount = () => {
        this.nft.mintCount += 1
        console.log(this.nft.mintCount)
        if (this.nft.mintCount > this.nftContext.maxMint)
          this.nft.mintCount = this.nftContext.maxMint

      },
      this.nft.decMintCount = () => {
        this.nft.mintCount -= 1
        if (this.nft.mintCount < 1)
          this.nft.mintCount = 1

      },
      // set up an NFT context related to a Prove Anything proof group
      this.nftContext = await PA.Ethereum.NFT.init({
        proofGroup: "zQZiZDMlVRYLD36xqHrG",
        site: "GalleryLand",
        window: window,
        callback: this.nftContextCallback
      })
  },
  methods: {
    async connectWallet() {
      // specific action to connect the wallet
      const result = await this.nftContext.connectWallet()
      console.log(result)
    },
    async doMint(number) {
      // mint an nft!
      const result = await this.nftContext.mint(number)
      console.log(result)
    },
    async doAltMint(number) {
      // mint an nft - in another ccy!
      const result = await this.nftContext.altMint(number)
      console.log(result)
    },
    // a callback trigger with some useful NFT changes
    nftContextCallback(type, params) {
      this.nft.noticies.push({ type, params })
      switch (type) {
        case "SET_ACCOUNT": console.log("Set account to:", params.account); break;
        case "CHAIN_CHANGED": console.log("Set chain to:", params.chainId, params.correctChain); break;
        case "ERROR": console.info("An unexpected error occured:", params); break;
        case "MESSAGE": console.info("Message:", params.message); break;
        case "MINT_DISABLED": console.error("Mint Disabled"); break;
        case "MINT_STARTED": console.log("Mint Beginning:", params); break;
        case "MINT_PENDING": console.log("Mint TX Pending:", params.tx.hash); break;
        case "MINT_SUCCEEDED": console.log("Mint TX Success:", params.tx.transactionHash); break;
        case "MINT_FAILED": console.error("Mint Failed:", params.tx.transactionHash); break;
        case "UPDATED_CONTRACT": console.log("Updated mint status from the contract:", params); break;
        case "CONTRACT_FETCH_FAILED": console.error("Failed to get the contract data", params.message); break;
        case "USER_REJECTED": console.error("User Rejected the action"); break;
        case "CONTRACT_CALL_ERROR": console.error("Error in contract call", params.message); break;
        case "ACCESSLIST_PROOF_FAILED": console.error("Not on the access List", params); break;
      }
      this.$forceUpdate()

    },
    async checkAccessList() {
      const result = await this.nftContext.checkIfAccessList()
      this.onAccessList = result
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss">


@import url('https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&family=Josefin+Slab&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&display=swap');

.theme--dark.v-application{
  background-color: #00001A !important;
}


.start {
  font-family: 'Josefin Slab', serif !important;
  text-transform: uppercase;
}


.v-dialog {}

.popupDlg {
  background-image: url('https://storage.googleapis.com/prove-anything.appspot.com/microsites/tinyunicornnfts.com/rainbow_border_full.png');
  background-color: #00000022 !important;
  width: 900px;
  height: 650px;

}

.chainImage {
  display: inline-block;
  margin: 10px;
  padding: 10px;
  border: 2px solid #FFFFFF;
  width: 100px;
  height: 80px;
}

.chainImage.selected {
  border: 2px solid #FF0000;

}

.popupDlg .popupInner {
  padding: 24px;
}

@media screen and (max-width: 992px) {
  .chainImage {
    margin: 0px;
    width: 60px;
    height: 50px;
  }

  .popupDlg .popupInner {
    padding: 12px;
  }
}



.character-page  .writing {
    font-family: 'Gloria Hallelujah', cursive !important;
}

.character-page  .avatar {
}

 .character-page  .bubble {
        border: 6px solid #EEE;
        border-radius: 20px;
        margin: 10px;
        padding: 10px;
        font-size: 1.3em;
    }

 .character-page {
    .social {
        background-color: #000;
        border-bottom: 1px solid #CCC;
        div {
            text-align: center;
        }
        a {
            text-decoration: none;
            color: #FFF;
            i { color: #FFF}
        }
    }
    .wallet-btn {
        z-index: 2;
        position: absolute;
        top:200px;
        left:50px;
    }
    .login-btn {
        z-index: 2;
        position: absolute;
        top:200px;
        right:50px;
    }    min-height: 3000px;

    .hero {
//        -webkit-clip-path: polygon(0 0,100% 0,100% 88%,50% 100%,0 88%);
//        clip-path: polygon(0 0,100% 0,100% 88%,50% 100%,0 88%);
    }
    .welcome {
        font-family: 'Josefin Slab', cursive;
        h1 {
            font-weight: 400;
            font-size: 3em;
        }
        h2 {
            font-weight: 400;
            font-size: 2em;
        }
        padding-top:80px;
        min-height: 400px;
        .box {
            font-size: 1em;
        }
    }
    .box {
        font-family: 'Josefin Slab', serif;
        margin: 20px auto;
        border: 2px solid #777;
        border-radius: 20px;
        font-size:1.2em;
      
    }
    .no-box {
        font-family: 'Josefin Slab', serif;
        font-size:1.2em;
        margin: 20px auto;
      
    }
    .text-h5 {
         font-family: 'Josefin Slab', serif !important;
    }
    .v-timeline-item {
        p {
            font-size:1.2em;
        }
    }
    .carousel {
        font-family: 'Josefin Slab', serif;
        &__box {
            div {
                max-width: 400px;
                margin: 20px auto;
                border: 2px solid #777;
                border-radius: 20px;
                min-height:300px;
                padding: 30px;
                font-size:1.5em;
            }
        }
        &__images {
            padding:20px 50px 20px 50px;
            .v-carousel {
                border-radius: 20px;
            }
        }
    }
}

.mintCount{
  font-size:30px;
  display: inline-block;
  border:1px solid #CCC;
  border-radius: 8px;
  margin: 0px 20px;
  padding: 10px 30px ;
}
.mintBox {
  font-size: 1.2em;
}
@media only screen and (max-width: 768px) {  
    .login-btn {
        display:none;
    }
}
</style>
